:root {
  --h-font-weight: 700;
  --h1-font-size: 30px;
  --h2-font-size: 24px;
  --h3-font-size: 18px;
  --body-font-family: "Lato", sans-serif;
  --body-font-weight: 400;
  --body-font-family-size: 16px;
  --body-font-family-size: 1.6;
  --profile-pic-width-height: 250px;
  --logo-width-size: 200px;
  --webpage-white: white;
  --webpage-colour-01: rgb(235, 235, 235);
  --webpage-colour-02: rgb(116, 116, 116);
  --webpage-colour-03: rgb(58, 58, 58);
}

html body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* background-color: #42c4e9; */
  background-color: var(--webpage-blue);
  background-color: white;
}

* {
  box-sizing: border-box;
}

@keyframes opacityReveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Homepage Styling */
.homePageContainer {
  height: 80vh;
  display: flex;
  justify-content: center;
}

.introContainer {
  width: 600px;
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 495px) {
  .introContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}

.profileImage {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-left: 10px;
}

.introTextContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

@media (max-width: 495px) {
  .introTextContainer {
    margin-left: 0px;
    align-items: center;
  }
}

.introText {
  text-align: start;
}

@media (max-width: 495px) {
  .introText {
    text-align: center;
  }
}

.codeLink {
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
}

.codeLink:hover {
  font-weight: bold;
}

/* Socials Styling */

.socialsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 100px;
  padding-bottom: 30px;
  background-color: white;
  align-items: center;
}

@media (max-width: 700px) {
  .socialsContainer {
    transform: translateY(100vh);
  }
}

.socials {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialsText {
  font-weight: bold;
}

.githubLogo {
  width: 40px;
  margin-right: 3px;
}

.linkedinLogo {
  width: 36px;
  margin-left: 3px;
}

/* About Page Styling*/

.introTextContainer {
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  align-self: flex-start;
}

.skillsHeading {
  background-color: white;
  width: 150px;
  border-radius: 12px;
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--webpage-blue);
  align-self: end;
  margin-left: 3%;
}

.downloadContainer {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
}

.downloadButton {
  font-size: 100%;
  background-color: #212529;
  outline: none;
  color: white;
  z-index: 8;
  width: 175px;
  border: none;
  height: 40px;
  border-radius: 15px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  min-height: 40px;
  margin-top: 50px;
}

.downloadButton:hover {
  width: 180px;
  height: 45px;
}

@media (max-width: 600px) {
  .downloadButton {
    transform: translate(-60px);
  }
}

a {
  color: var(--website-green);
}

body {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  font-size: var(--body-font-family-size);
  line-height: var(--body-font-family-size);
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h-font-weight);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h-font-weight);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h-font-weight);
}

a {
  color: var(--webpage-black);
}

a:hover,
a:focus {
  text-decoration: none;
}

a:visited {
  color: grey;
}

a:active {
  color: var(--webpage-black);
}

.whiteMask {
  /* background-color: red; */
  width: 100%;
  z-index: 5;
  height: 30px;
}

/* Contact Page Styling */
.contactPageContainer {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  margin-bottom: 120px;
  z-index: 1;
}

.contactWhiteOne {
  position: relative;
  width: 45%;
  height: 98%;
  background-color: white;
  align-self: flex-end;
  z-index: 2;
  border-radius: 70% 0 0 0;
  transform: translate(1px);
}

.contactWhiteTwo {
  position: relative;
  width: 30%;
  height: 98%;
  background-color: white;
  align-self: flex-end;
  z-index: 2;
}

.greyContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: -1;
}

.contactGreyOne {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: var(--webpage-grey);
  align-self: flex-end;
  z-index: -2;
  border-radius: 70% 0 0 0;
}

.contactGreyTwo {
  position: relative;
  width: 30%;
  height: 100%;
  background-color: var(--webpage-grey);
  align-self: flex-end;
  z-index: -2;
}

.contactPageContainerReveal {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 160px;
  margin-bottom: 200px;
  z-index: -2;
}

@media (min-width: 600px) {
  .contactPageContainer {
    animation: opacityReveal 1s linear;
    z-index: -1;
  }

  .contactPageContainerReveal {
    opacity: 0;
    animation: opacityHide 1s linear;
    z-index: -1;
  }
}

.contact {
  font-size: 30px;
}

.contactInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 30px;
  z-index: 2;
  transform: translate(50px);
}

.infoContainer {
  transform: translate(10%);
}

.hidden {
  opacity: 0;
}

.contactInfoText {
  font-size: 20px;
}

.phoneText {
  font-size: 20px;
  margin-bottom: 40px;
}

.contactColourContainers {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: -1;
}

@media (max-width: 800px) {
  .contactInfoText {
    font-size: 18px;
  }

  .hidden {
    display: none;
  }

  .linkedInURL {
    display: none;
  }
}

@media (max-width: 600px) {
  .contactWhiteOne {
    display: none;
  }

  .contactWhiteTwo {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    align-self: flex-end;
    z-index: 2;
  }

  .greyContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: -1;
  }

  .contactGreyOne {
    display: none;
  }

  .contactGreyTwo {
    display: none;
  }

  .contactInfoContainer {
    transform: translate(0px);
  }

  .infoContainer {
    transform: translate(0%);
  }
}

.githubLogoContact {
  width: 50px;
  transform: translate(15px, 10px);
}

.linkedinLogoContact {
  width: 45px;
  transform: translate(1px, 10px);
}

/* Section One */
/* Landing Page */

/* Carousel Dots */
.carousel__dot {
  background-color: var(--webpage-colour-03);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
}

.carousel__dot--selected {
  background-color: var(--webpage-colour-01);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 2px var(--webpage-colour-02);
}

:root {
  --webpage-white: white;
  --webpage-colour-01: rgb(235, 235, 235);
  --webpage-colour-02: rgb(116, 116, 116);
  --webpage-colour-03: rgb(58, 58, 58);
}

@keyframes opacityReveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.workSectionContainer {
  width: 100%;
  margin-top: 50px;
  height: 100%;
  position: relative;
}

.background {
  position: absolute;
  background-color: var(--webpage-white);
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.457);
}

.bold {
  font-weight: bold;
  font-size: 20px;
}

.workSection {
  padding-bottom: 70px;
  background-color: white;
  z-index: 9;
  position: relative;
}

.workSectionReveal {
  padding-bottom: 70px;
  background-color: white;
  z-index: 3;
}

.workHeadingContainer {
  display: flex;
  justify-content: center;
}

.workHeading {
  text-align: center;
  width: 200px;
  border-radius: 15px;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
  font-size: 2.5em;
  transform: translateY(30px);
}

@media (min-width: 600px) {
  .workSection {
    animation: opacityReveal 1s linear;
    z-index: 3;
  }

  .workSectionReveal {
    opacity: 0;
    animation: opacityHide 1s linear;
    z-index: 3;
  }
}

.workSlideContainer {
  display: flex;
  justify-content: center;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1000px) {
  .workSectionContainer {
    display: none;
  }
}

@media (min-width: 1000px) {
  .workSectionContainer {
    display: block;
  }
}

.popupText {
  text-align: center;
  padding: 10px;
}

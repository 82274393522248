.moreInfoContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.moreInfo {
  position: absolute;
  background-color: white;
  width: 40%;
  margin-right: 10%;
  border-radius: 30px;
  padding-left: 3%;
  padding-right: 3%;
  z-index: 8;
}

.linkContainer {
  border-top: solid rgba(0, 0, 0, 0.3) 1px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  height: 60px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.projectTitles {
  text-align: center;
  font-size: 30px;
}

.workText {
  font-weight: 600;
  font-size: 18px;
}

.center {
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: bold;
  font-size: 20px;
}

.pokemonGitPopup {
  border: solid black;
  background-color: white;
  border-radius: 12px;
  width: 300px;
  height: 120px;
  position: absolute;
  transform: translateY(-100px);
  z-index: 8;
}

.popupText {
  text-align: center;
  padding: 10px;
}

.pokemonGithub {
  width: 45px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.pokemonLive {
  width: 41px;
  transform: translateY(2px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.caseStudy {
  width: 47px;
  transform: translateY(2px);
  margin-left: 13px;
  margin-right: 10px;
  margin-top: 10px;
}

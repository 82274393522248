.workPageContainer {
  margin-top: 100px;
  z-index: 2;
}

.blueBackground {
  position: absolute;
  background-color: var(--webpage-blue);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.workHeadingContainer {
  display: flex;
  justify-content: center;
}

.workHeading {
  text-align: center;
  width: 200px;
  border-radius: 15px;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
  font-size: 2.5em;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding-left: 2%;
  padding-right: 3%;
}

.grid__item {
  display: inline-block;
  width: 33%;
  padding: 5%;
  border-radius: 10px;
  text-decoration: none;
  border: solid var(--webpage-blue) 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.grid__item:visited {
  color: inherit;
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 0;
  }

  .grid__item {
    background-color: var(--work-projects-background);
    width: auto;
    min-height: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.projectTitles {
  text-align: center;
  font-size: 30px;
}

.workText {
  font-weight: 600;
  font-size: 20px;
}

.pokedexImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.apiImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.reactImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.angularImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chatImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.meetImageContainer {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.bold {
  font-weight: bold;
  font-size: 20px;
}

.imageContainer {
  border-radius: 12px;
  z-index: 7;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pokedexImage {
  width: 100%;
  align-self: flex-end;
  border: solid #3a3a3a 2px;
  border-radius: 12px;
}

.apiImage {
  width: 100%;
  align-self: flex-end;
  border: solid #3a3a3a 2px;
  border-radius: 12px;
}

.reactImage {
  width: 100%;
  align-self: flex-end;
  border: solid #3a3a3a 2px;
  border-radius: 12px;
}

.linkContainer {
  border-top: solid rgba(0, 0, 0, 0.3) 1px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  height: 60px;
}

.pokemonGitPopup {
  border: solid black;
  background-color: white;
  border-radius: 12px;
  width: 300px;
  height: 120px;
  position: absolute;
  transform: translateY(-100px);
  z-index: 8;
}

.popupText {
  text-align: center;
  padding: 10px;
}

.pokemonGithub {
  width: 45px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.pokemonLive {
  width: 41px;
  transform: translateY(2px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.caseStudy {
  width: 47px;
  transform: translateY(2px);
  margin-left: 13px;
  margin-right: 10px;
  margin-top: 10px;
}

.angularImage {
  width: 100%;
  align-self: flex-end;
  border: solid #3a3a3a 2px;
  border-radius: 12px;
}

.chatImage01 {
  width: 100%;
  align-self: flex-end;
  border: solid #3a3a3a 2px;
  border-radius: 12px;
}

.pokemonSitePopup {
  border: solid black;
  background-color: white;
  border-radius: 12px;
  width: 300px;
  height: 120px;
  position: absolute;
  transform: translateY(-100px);
  z-index: 8;
}

@media all and (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media all and (min-width: 500px) and (max-width: 1000px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media all and (max-width: 750px) {
  .grid {
    grid-gap: 10px;
  }

  h1 {
    font-size: 22px;
  }
}

@media (max-width: 1000px) {
  .workPageContainer {
    display: flex;
  }
}

@media (min-width: 1000px) {
  .workPageContainer {
    display: none;
  }
}

@media (min-width: 700px) {
  .imageCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 6;
    border-radius: 12px;
    opacity: 0.85;
  }

  .imageContainer {
    border-radius: 12px;
    z-index: 7;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(1px);
  }

  .imageContainerNoBlur {
    border-radius: 12px;
    z-index: 7;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(0px);
  }
}

:root {
  --webpage-white: white;
  --webpage-colour-01: rgb(235, 235, 235);
  --webpage-colour-02: rgb(116, 116, 116);
  --webpage-colour-03: rgb(58, 58, 58);
  --webpage-colour-04: rgba(201, 201, 201, 0.1);
}

@keyframes opacityReveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.aboutPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--webpage-white);
}

.aboutContainer {
  display: flex;
  width: 90%;
  justify-content: center;
}

.aboutContainerReveal {
  display: flex;
  width: 90%;
  justify-content: center;
}

.sectionContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}

.aboutSkillsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.aboutTextContainer {
  width: 600px;
  margin-bottom: 50px;
  text-align: start;
  background-color: white;
  align-self: flex-start;
  height: 606px;
  border: 3px;
  background-color: rgba(211, 211, 211, 0);
}

.aboutTextSubContainer {
  border: solid var(--webpage-colour-01) 3px;
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  background-color: white;
  padding-left: 60px;
  padding-right: 60px;
}

.aboutSectionContainer {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about {
  font-weight: 700;
  font-style: italic;
  font-size: 2.5em;
  color: black;
  font-family: "Montserrat", sans-serif;
  margin-top: 100px;
}

.aboutText {
  font-weight: 550;
  font-size: 18px;
}

.skillsContainer {
  margin-bottom: 50px;
}

.skillTechnologies {
  display: flex;
  justify-content: center;
}

.frontEndSkills {
  width: 300px;
  height: 600px;
  background-color: white;
  border-radius: 25px 0 0 25px;
  padding: 20px;
  padding-left: 63px;
  border: 3px;
  border-right: 3px;
  border-color: var(--webpage-colour-01);
  border-style: solid;
}

.frontEnd {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.skill {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.skillsText {
  font-size: 18px;
  font-weight: bold;
}

.htmlIcon {
  width: 45px;
  height: 45px;
  margin-left: 66px;
}

.cssIcon {
  width: 45px;
  height: 45px;
  margin-left: 84px;
}

.jsIcon {
  width: 45px;
  height: 45px;
  margin-left: 22px;
}

.tsIcon {
  width: 43px;
  height: 43px;
  margin-left: 20px;
}

.mongoIcon {
  width: 59px;
  margin-left: 19px;
}

.postmanIcon {
  width: 55px;
  margin-left: 40px;
}

.apiIcon {
  width: 80px;
  margin-left: 18px;
}

.nodeIcon {
  width: 50px;
  margin-left: 40px;
}

.mongooseIcon {
  width: 55px;
  margin-left: 17px;
}

.bootstrapIcon {
  width: 54px;
  margin-left: 23px;
}

.reactIcon {
  width: 54px;
  margin-left: 62px;
}

.reduxIcon {
  width: 54px;
  margin-left: 56px;
}

.awsIcon {
  width: 54px;
  margin-left: 70px;
}

.backEndSkills {
  width: 300px;
  height: 600px;
  /* margin-left: 50px; */
  background-color: white;
  border-radius: 0 25px 25px 0;
  padding: 20px;
  padding-left: 63px;
  border: 3px;
  border-left: 0px;
  border-color: var(--webpage-colour-01);
  border-style: solid;
}

.sectionTwoContainer {
  height: 500px;
  z-index: 5;
}

.about {
  color: black;
}

@media (max-width: 1300px) {
  .aboutContainer {
    flex-direction: column;
    align-items: center;
  }

  .aboutContainerReveal {
    flex-direction: column;
    align-items: center;
  }

  .skillsContainer {
    margin-left: 0%;
    align-self: center;
  }

  .aboutTextContainer {
    margin-right: 0%;
    align-self: center;
    height: 100%;
    width: 100%;
  }

  .skillsContainer {
    width: 100%;
  }

  .about {
    margin-top: 0px;
  }

  .aboutSectionContainer {
    margin-right: 0px;
  }

  .aboutSkillsContainer {
    margin-left: 0px;
  }
}

@media (max-width: 1000px) {
  .about {
    margin-top: 0px;
  }

  .aboutTextContainer {
    margin-top: 0px;
  }
}

@media (max-width: 700px) {
  .skillTechnologies {
    width: 90%;
    flex-direction: column;
    margin-left: 0;
  }

  .frontEndSkills {
    margin-right: 0px;
    border-radius: 30px;
  }

  .backEndSkills {
    margin-left: 0px;
    margin-top: 30px;
    border-radius: 30px;
    border-left: 3px;
  }
}

@media (max-width: 700px) {
  .backEndSkills {
    border: solid 3px lightgray;
  }
}

@media (min-width: 600px) {
  .aboutContainer {
    animation: opacityReveal 1s linear;
  }

  .aboutContainerReveal {
    opacity: 0;
    animation: opacityHide 1s linear;
  }
}

@media (max-width: 500px) {
  .aboutSkillsContainer {
    width: 300px;
  }
}
